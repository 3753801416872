@mixin tabIconDimension() {
  &[class*="weople-"] {
    width: 1em;
    height: 1em;
  }
}

@mixin tabIconInactiveLabel() {
  +.tab-button-text {
    color: #cfcfcf;
  }
}

@mixin tabIconActive() {
  @include tabIconDimension();
  &[class*="weople-investi"] {
    content: url(../assets/imgs/icon/caveu_color@3x.png);
  }
  &[class*="weople-premi"] {
    content: url(../assets/imgs/icon/icon_gain_relax_active.png);
  }
  &[class*="weople-esercita"] {
    content: url(../assets/imgs/icon/attiva_diritti_color@3x.png);
  }

}

@mixin tabIconInactive() {
  @include tabIconDimension();
  &[class*="weople-investi"] {
    content: url(../assets/imgs/icon/investi_inactive@3x.png);
  }
  &[class*="weople-premi"] {
   content: url(../assets/imgs/icon/icon_gain_relax_inactive.png);
  }
  &[class*="weople-esercita"] {
    content: url(../assets/imgs/icon/attiva_diritti_inactive@3x.png);
  }
  @include tabIconInactiveLabel();
}

//---MEDIA QUERIES----------------

@mixin small-phone {
  @media screen and (max-width: var(--small-phone)){
    @content;
  }
}

@mixin web {
  @media screen and (min-width: var(--web)){
    @content;
  }
}

@mixin tablet {
  @media screen and (min-height: var(--web)) and (min-width: var(--web)){
    @content;
  }
}


