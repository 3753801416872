// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import "mixins.scss";




$modal-inset-width: 100%;
$modal-inset-height-large: 100%;
$modal-inset-height-small: 100%;
// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here
$item-ios-thumbnail-size:42px;
$toolbar-ios-title-text-color: rgba(72, 139, 255, 0);

// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here
$text-input-md-show-focus-highlight: false !default;
$text-input-md-show-valid-highlight: $text-input-md-show-focus-highlight !default;
$text-input-md-show-invalid-highlight: $text-input-md-show-focus-highlight !default;
$label-md-text-color:#000000;
$label-md-text-color-focused:#000000;

// App Windows Variables
// --------------------------------------------------
// Windows only Sass variables can go here




// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.



// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/ionicons/



// Fonts
// --------------------------------------------------


$toolbar-background : rgba(72, 139, 255, 0);
$half-margin: 0.4em;

// Other
// --------------------------------------------------
 @import "mixins";


$colors: (
  primary:    #009DCC,
  secondary:  #5e7be9,
  danger:     #f53d3d,
  light:      #f4f4f4,
  dark:       #232323,
  white:    #ffffff,
  grey: #a7a7a7,
  light-grey: #fafafa,
  divider-color:#e5e5e5,
  azzurro-chiaro:#5FC4E1,


  // CASSETTE
  cassetta-full: rgb(0,157,204),
  cassetta-empty: rgb(210, 210, 210),

  // social
  facebook: #466BAE,
  instagram: #C045C6,
  twitter: #000000,
  linkedin: #1B79B1,

  // account
  google: #D84C43,
  apple: #B3B3B3,

  // loyalty
  carrefour: #003F8A,
  conad:#F6842A,
  coop: #ED1C24,
  esselunga: #003876,
  gigante: #36549C,
  iper: #00A455,
  unes: #EC752D,
  auchan: #009B59,
  ikeaFamily: #FF8500,
  decathlon: #0496F4,
  mediaworld: #E70000,
  laTuaCard: #F156A1,
  panorama: #0065AE,
  coin: #000000,
  unieuro: #FBA700,
  acquaESapone: #10409D,
  feltrinelli: #F02316,
  pewex: #303786,
  cisalfaSport: #F18E01,
  md: #EF5D5E,
  altro: #009DCC,

  // ecommerce
  amazon: #FA9738,

  // punteggio
  punti-acquisiti: #FCBD07,
  punti-attesa: #C0C0C0,

  button-confirm-popup: #009DCC
);

$background: #009dcc;
$background-shade: mix(#000, $background, 12%);
$background-tint: mix(#fff, $background, 10%);

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #009dcc;
  --ion-color-primary-rgb: 0, 157, 204;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0085ad;
  --ion-color-primary-tint: #2bbae6;

  /** secondary **/
  --ion-color-secondary: #5e7be9;
  --ion-color-secondary-rgb: 94, 123, 233;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #5e7be9;
  --ion-color-secondary-tint: #5e7be9;

  /** tertiary **/
  --ion-color-tertiary: #5fc4e1;
  --ion-color-tertiary-rgb: 95, 196, 225;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5fc4e1;
  --ion-color-tertiary-tint: #5fc4e1;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #f53d3d;
  --ion-color-danger-tint: #f53d3d;

  /** dark **/
  --ion-color-dark: #232323;
  --ion-color-dark-rgb: 35, 35, 35;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #a7a7a7;
  --ion-color-medium-rgb: 167, 167, 167;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --half-margin: 0.4em;

  --divider-color:#e5e5e5;
  --azzurro-chiaro:#5FC4E1;


  --small-phone: 350px;
  --web: 768px;


  // CASSETTE
  --cassetta-full: rgb(0,157,204);
  --cassetta-empty: rgb(210, 210, 210);

  // social
  --facebook: #466BAE;
  --instagram: #C045C6;
  --twitter: #000000;
  --linkedin: #1B79B1;

  // account
  --google: #D84C43;
  --apple: #B3B3B3;

  // loyalty
  --carrefour: #003F8A;
  --conad:#F6842A;
  --coop: #ED1C24;
  --esselunga: #003876;
  --gigante: #36549C;
  --iper: #00A455;
  --unes: #EC752D;
  --auchan: #009B59;
  --ikeaFamily: #FF8500;
  --decathlon: #0496F4;
  --mediaworld: #E70000;
  --laTuaCard: #F156A1;
  --panorama: #0065AE;
  --coin: #000000;
  --unieuro: #FBA700;
  --acquaESapone: #10409D;
  --feltrinelli: #F02316;
  --pewex: #303786;
  --cisalfaSport: #F18E01;
  --md: #EF5D5E;
  --altro: #009DCC;

  // ecommerce
  --amazon: #FA9738;

  // punteggio
  --punti-acquisiti: #FCBD07;
  --punti-attesa: #C0C0C0;

  --button-confirm-popup: #009DCC;
  --toolbar-ios-title-text-color: rgba(72, 139, 255, 0);
  --ion-toolbar-background : rgba(72, 139, 255, 0);

  --border-radius: 20px;

  ion-button{
    --border-radius: 5px;
  }
}

@media (prefers-color-scheme: light) {

  .ios body {
    --ion-background-color: white;
    --ion-background-color-rgb: 255,255,255;
  }
}