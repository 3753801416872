// http://ionicframework.com/docs/theming/

// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
//

@import "../theme/mixins.scss";
@import "../theme/variables.scss";

* {
  font-family: 'Lato', sans-serif;

  p,
  span {
    cursor: default;
  }
}

.right {
  float: right;
}

/* styles for trasparent navbar */

ion-header.transparent_toolbar .toolbar-title.toolbar-title-md,
.toolbar-title.toolbar-title-ios,
.toolbar-title.toolbar-title-wp {
  color: black;
}

ion-header.transparent_toolbar ion-icon.back-button-icon.icon {
  color: var(--ion-color-primary, --base);
}

ion-header.transparent_toolbar span.back-button-text-ios {
  color: var(--ion-color-primary, --base);
}

ion-title {
  font-weight: 600;
  font-size: 12pt;
  letter-spacing: -0.16pt;
  cursor: default;
}

ion-back-button {
  color: var(--ion-color-primary);
  font-size: 34px;
}

.action-sheet-group {
  overflow: hidden !important;
}

.primary {
  color: var(--ion-color-primary, --base);
}

.destructive {
  color: red !important;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 13rem;
  }
}

.img-medium {
  display: block;
  width: auto !important;
  height: 80px !important;
  margin: auto;
}

.img-large {
  display: block;
  width: auto !important;
  height: 140px !important;
  margin: 32px auto;
}

.subtitle {
  font-size: 24px;
  padding-top: 0px;
  margin: 0;
  font-weight: bold;
}

.description {
  font-size: 1.5rem;
  line-height: 19px;
}

.description1 {
  margin: 20px 0 0 0;
  line-height: 22px;
}

.description2 {
  margin: 20px 52px 10px 52px;
  line-height: 22px;
}

.absolute-center {
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.toolbar-extra-button {
  margin: 0 3px 0 0;
  font-size: 16pt;
  letter-spacing: -0.21pt;
}

.title-area {
  color: var(--ion-color-primary, --base);
  font-weight: bold;
}

.footer-action {

  height: 4.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  span {
    color: black;
  }

  .avanti {
    font-size: 1.2em;

    b {
      color: var(--ion-color-primary, --base);
    }
  }

  .delete-text {
    color: var(--danger, --base);
    font-size: 16px;
    letter-spacing: 0;
    font-weight: bold;
  }
}

.loading-wrapper.sc-ion-loading-md {
  border-radius: 12px;
}

.footer-gradient {
  background: linear-gradient(135.42deg, var(--ion-color-primary, --base) 0%, #6078EA 100%);

  span {
    color: white;
  }

}

// colora tutti i back button
ion-header ion-icon.back-button-icon.icon {
  color: var(--ion-color-primary);
}

.spaced-grid {
  .row {
    margin: var(--half-margin);
  }

  .col {
    padding: var(--half-margin);
  }
}

/*
  spesso usata insieme alle direttive padding e ion-fixed
  es.
  <ion-content  no-bounce>

    <div ion-fixed class="main_container_fixed" padding >
    ...
    </div>
  </ion-content>
   */
.main_container_fixed {
  z-index: 2;
  width: 100%
}

/* Generic form styles */

form {
  ion-item {
    padding: 0 !important;

    ion-label {
      font-weight: 800;
      letter-spacing: .2em;
      margin-left: 4px !important;
      margin-bottom: 4px !important;
    }

    .item-inner {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    ion-input {
      padding: 1rem 8px;

      .custom {
        --highlight-color-valid: var(--ion-color-primary);
        --padding-start: 10px !important;
        --placeholder-color: grey;
        font-size: 16px;
      }
    }



    .rounded {
      border-radius: 4px !important;
      border: 1px solid #aaaaaa;
      font-size: 14px;
      height: 65px;
      border-color: #DBDBDB !important;
    }

    .right-icon {
      position: absolute;
      right: 2rem;
      --color: gray;
    }

    .fake-enabled {
      .text-input[disabled] {
        opacity: 1;
      }
    }

    ion-select {
      .select-text {
        padding: 1.1rem 8px;
      }

      .select-icon {
        height: auto !important;
      }
    }

    .datetime-md {
      padding: 0;
    }

    .datetime-ios {
      padding: 0;
    }

    .datetime-text {
      padding: 1.9rem 8px;
    }

  }

  .error {
    color: red !important;
    margin-left: 16px;
  }

  .visible {
    display: block !important;
  }

  .hidden {
    display: none;
  }

  .text-black {
    color: black !important;
  }

  .text-green {
    color: green !important;
  }

}


form button[type='submit'] {
  margin-top: 16px
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: .8em !important;
  letter-spacing: .1em;
  font-weight: 500;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: .8em !important;
  letter-spacing: .1em;
  font-weight: 500;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: .8em !important;
  letter-spacing: .1em;
  font-weight: 500;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: .9em !important;
  letter-spacing: .1em;
  font-weight: 500;
}

.gradient-background {
  background: var(--ion-color-primary, --base);
}

.container-light-grey {
  background: var(--light-grey, --base);
  border-bottom: 1px solid var(--divider-color, base);

  @include web() {
    background: white;
  }
}

.container-light-grey-tutorial {
  background: var(--ion-color-primary, --base);
  border-bottom: 1px solid var(--divider-color, base);
  width: 100%;
}



.password-security-title {
  margin-bottom: 0.5rem;
}

// ==== BARRA E LABEL SICUREZZA PASSWORD - inizio ====
.password-security-container {
  width: 100%;
  height: 10px;
  margin: 1rem 0;
  background-color: #cccccc;
  border: 1px solid #aaaaaa;
  border-radius: 4px !important;
}

.password-security-box-1,
.password-security-box-2,
.password-security-box-3,
.password-security-box-line {
  float: left;
  height: 8px;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.password-security-box-1 {
  background-color: #ef6969;
  border-left: 1px solid #ef6969;
  border-top: 1px solid #ef6969;
  border-bottom: 1px solid #ef6969;
  width: 33.3%;
}

.password-security-box-2 {
  background-color: #fff243;
  border-left: 1px solid #fff243;
  border-right: 1px solid #fff243;
  border-bottom: 1px solid #fff243;
  width: 66.6%;
}

.password-security-box-3 {
  width: 99.9%;
  background-color: #17c900;
  border-bottom: 1px solid #17c900;
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.password-security-box-line {
  background-color: #e03939;
  border-right: 1px solid #ef6969;
  border-bottom: 1px solid #ef6969;
}

// ==== BARRA E LABEL SICUREZZA PASSWORD - fine ====

// ==== MODALE PREMI E OPPORTUNITA' - inizio ====
ion-modal {
  &::part(content) {
    /* Set the height of the content then ask Alessandro how to manage it without the need to set the height diff to 100% */
    padding: auto;
    border-radius: 8px;

    /* The following solution take care of small and big screen*/
    max-height: 80%;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      max-height: 60%;
      max-width: 80%;
    }
  }

  &::part(backdrop) {
    visibility: visible;
    opacity: 0.7 !important;
    z-index: 0;
    background: #000;
  }

  ion-title {
    padding: 0 !important;
  }

  ion-toolbar {
    min-height: 2.5vh;
  }


  .toolbar {
    min-height: 60px;
    padding: 0 !important;

    .toolbar-title {
      font-weight: 600;
      margin-top: 1.8vh;
      font-size: 3vh;
    }

    .padding-icon {
      padding-right: 1.8vh;
      padding-top: 1.8vh;
    }


  }


  ion-icon {
    font-size: 2.4vh;
    font-weight: 700;
    color: #000;
  }


  ion-item {
    --background-focused: white;
    --background-hover: white;
  }

  .text {
    font-size: 2.4vh;
    line-height: 3vh;
    padding-left: 1.5vh;
    padding-right: 1.5vh;
  }

  .popup-subtitle {
    font-weight: 600;
    color: #009DCC;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    font-size: 3.2vh;
  }

  .top-image {
    margin-top: 3.25vh;
    width: 8vh;
  }

  .go-to-profile {
    font-size: 3vh;
    font-weight: bold;
    color: var(--ion-color-primary, --base);
  }

  .modal-backdrop-bg {
    transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    background-color: #000;
    opacity: 0;
  }

  .active .modal-backdrop-bg {
    opacity: 0.5;
  }

  .checkbox-icon {
    width: 2.3rem;
    height: 2.3rem;
    border-width: 3px;
    border-color: var(--ion-color-primary, --base);

    .checkbox-inner {
      height: 1.4rem;
      left: 0.5rem;
    }
  }

  .container-fixed-bottom {
    position: absolute;
    bottom: 0px;
    background: transparent !important;
  }

  .remember-me {
    width: 95%;
    padding: 6px;
  }

  .buttons {
    width: 100%;
  }

  .divider {
    width: 80%;
    height: 1px;
    background-color: #E5E5E5;
    margin: auto;
  }

  .ion-page {
    display: flex;
  }

  //Modifiche per tablet
  @include tablet() {
    .text-small {
      font-size: 16px !important;
      line-height: 22px !important;
      margin: 12px 0 !important;
    }

    .ion-page {
      height: 640px !important;
      width: 460px !important;
    }

    .top-image {
      margin-top: 48px !important;
      width: 120px !important;
    }
  }

  .expand-wrapper {
    transition: max-height 0.4s ease-in-out;
    overflow: hidden;
    height: auto;
  }

  .collapsed {
    max-height: 0 !important;
  }

  .btn-close {
    zoom: 1.6;
    font-weight: bold;
    padding: 1.5vh;
    position: absolute;
    right: 0;
    z-index: 10000;
  }

  .archive-top-image {
    margin-top: 3.25vh;
    width: 12vh;
  }

  .brand-image {
    width: 10vh;
  }

  .text-connessione {
    font-size: 1.8vh;
    line-height: 3vh;
    color: #4d4d4d;
    margin: 2vh 0;
    padding-left: 1.5vh;
    padding-right: 1.5vh;
  }
}

.popup-html {
  @extend ion-modal;

  .ion-page {
    height: 90vh;
    width: 85vw;
  }
}

.popup-valutation-offer {
  @extend ion-modal;

  .top-image {
    margin: 9vh auto 3vh;
    width: 9vh;
  }

  .subtitle {
    margin-top: 2vh;
  }

  .text {
    font-size: 2.3vh;
    line-height: 3vh;
    margin: 2vh 0;
  }

  .ion-page {
    height: 50vh;
    width: 85vw;
  }

  .button-popup {
    height: 4em;
    width: 9em;
  }

  //Modifiche per tablet
  @include tablet() {
    .ion-page {
      height: 300px !important;
      width: 400px !important;
    }
  }
}

.popup-complimenti-offer-confirm {
  @extend ion-modal;

  .top-image {
    margin: 9vh auto 3vh;
    width: 9vh;
  }

  .subtitle {
    margin-top: 2vh;
  }

  .text {
    font-size: 2.3vh;
    line-height: 3vh;
    margin: 2vh 0;
  }

  .ion-page {
    height: 50vh;
    width: 85vw;
  }

  .button-popup {
    height: 4em;
    width: 9em;
  }

  //Modifiche per tablet
  @include tablet() {
    .ion-page {
      height: 450px !important;
      width: 400px !important;
    }
  }
}

.popup-delete-document {
  @extend ion-modal;

  ion-content {
    height: 95%;
  }

  &::part(content) {
    height: 50%;
  }

  .top-image {
    margin: 9vh auto 3vh;
    width: 9vh;
  }

  .text {
    font-size: 2.3vh;
    line-height: 3vh;
    margin: 1.8vh 0;
  }


  //Modifiche per tablet
  @include tablet() {
    .ion-page {
      height: 500px !important;
      width: 400px !important;
    }
  }


}

.modal-recaptcha {
  &::part(content) {
    height: 32%
  }
}


.modal-animation {

  &::part(content) {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    background-color: transparent;
    border-radius: 0px;
  }

  &::part(backdrop) {
    background-color: transparent;
  }


  #gif {
    background: url("../assets/imgs/animation/unlock/unlock_01.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 120%;
    background-color: transparent;
    height: 100%;

  }
}

.conf-numb {
  @extend ion-modal;

  ion-content{
    max-height: 100%;
    height: 100%;
  }

  &::part(content) {
    height: 100% !important;
    width: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important;
    background-color: transparent;
    border-radius: 0px;
  }

  &::part(backdrop) {
    background-color: transparent;
  }

}

.upload-process-modal {
  &::part(content) {
    max-height: 35%;

    @media screen and (max-width: 768px) {
      max-height: 35%;
      max-width: 80%;
    }
  }
}

.popup-visitor {
  @extend ion-modal;

  .text {
    font-size: 2.4vh;
    line-height: 3vh;
    margin: 2vh 0;
  }

  .ion-page {
    height: 40vh;
    width: 85vw;
  }

  //Modifiche per tablet
  @include tablet() {
    .ion-page {
      height: 360px !important;
      width: 400px !important;
    }
  }

}


.popup-alert-tutorial {
  &::part(content) {
    max-height: 35%;


    @media screen and (max-width: 768px) {
      max-height: 35%;
      max-width: 80%;
    }
  }
}

.popup-upload-completed {
  @extend ion-modal;

  &::part(content) {
    max-height: 40%;

    @media screen and (max-width: 768px) {
      max-height: 45%;
      max-width: 80%;
    }
  }
}

.popup-document-exit {
  @extend ion-modal;

  .text {
    font-size: 2.4vh;
    line-height: 3vh;
    margin: 2vh 0;
  }

  .ion-page {
    height: 36vh;
    width: 85vw;
  }

  //Modifiche per tablet
  @include tablet() {
    .ion-page {
      height: 360px !important;
      width: 400px !important;
    }
  }

}

.popup-share-web {
  @extend ion-modal;

  .text {
    font-size: 2.4vh;
    line-height: 3vh;
    margin: 2vh 0;
  }

  .ion-page {
    height: 42vh;
    width: 85vw;
  }

  //Modifiche per tablet
  @include tablet() {
    .ion-page {
      height: 400px !important;
      width: 380px !important;
    }
  }
}

.popup-primo-grant {
  @extend ion-modal;

  .top-image {
    margin: 9vh auto 3vh;
    width: 9vh;
  }

  .text {
    font-size: 2.4vh;
    line-height: 3vh;
    margin: 2vh 0;
  }

  .ion-page {
    height: 60vh;
    width: 85vw;
  }
}

.popup-ultimo-grant {
  @extend ion-modal;

  .top-image {
    margin: 9vh auto 3vh;
    width: 9vh;
  }

  .text {
    font-size: 2.4vh;
    line-height: 3vh;
    margin: 2vh 0;
  }

  .ion-page {
    height: 63vh;
    width: 85vw;
  }
}

.popup-cassetta-detail {
  @extend ion-modal;

  .subtitle {
    font-weight: 600;
    color: var(--ion-color-primary, --base);
    margin-top: 4vh;
    font-size: 3.2vh;
    padding: 0;
  }

  .text {
    font-size: 2.4vh;
    line-height: 3vh;
    margin: 2vh 0;
  }

  .top-image-cassetta {
    margin-top: 9vh;
    height: 14vh;
    width: 14vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    display: inline-flex;
    align-self: center;
  }

  .top-category-container {
    margin-top: 8vh;
    margin-bottom: 2vh;

    ion-col {
      text-align: center;
      margin: auto;
    }

    .point-text {
      font-size: 8.2vh;
      padding-left: 2vh;
      color: var(--punti-acquisiti, --base);
    }

    .coin-image {
      width: 15vh;
      margin: auto 6vh auto auto;
    }

  }
}

.icon-large-card,
.icon-medium-card {
  color: var(--ion-color-primary, --base);
}

.icon-medium-card {
  margin: 6px;
  zoom: 1.4;
  font-size: 2.5em;
}

.icon-large-card {
  zoom: 3;
}

.container-white {
  background: #ffffff;
}

.container-grey {
  background-color: #f0f0f0;
}

.sub-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
}

.item-half-container {
  margin-top: 12px;
  margin-bottom: 12px;
  width: 50%;
}

.container-item-right {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  padding: 8px 4px;
}

//CSS PER IL TAB CUSTOM

.custom-tab {
  width: 50%;
  text-align: center;
}

.radio-group-custom {
  width: 100%;
  margin-bottom: 12px;
}

.radio-icon {
  visibility: hidden;
  width: 0px !important;
}

.item .radio {
  margin: 0px !important;
}

.item-radio-checked {
  border-bottom: 3px solid #86c1f1 !important;
}

//FINE CSS TAB CUSTOM

.row-items-double {
  width: 50%;
  max-width: 50%;
}

.row-items-triple {
  width: 33%;
  max-width: 33%;
}


// === CASSETTE - INIZIO ===
.cassetta {
  height: 5.6em;
  background-color: white;
  background-size: cover !important;
  border-radius: 0.5em;
  display: flex;

  // IN ATTESA DI GRAFICHE DEFINITIVE
  border: 1px solid #DBDBDB;

  h4 {
    margin-left: 1em;
    font-size: 1.3em;
    color: var(--ion-color-primary, --base);
    font-weight: bold;
  }

  .centered-text {
    margin: auto !important;
    cursor: default;
  }

  .padding-left {
    padding-left: 11px;
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 0;
  }

  .icon-right {
    zoom: 1.2;
    font-weight: bold;
    position: absolute;
    right: 0.9em;
    top: 1.15em;
  }

  ion-icon {
    color: var(--ion-color-primary, --base);
  }

  .logo-img {
    @extend %center-column;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.aggiunta {
    border: none;
    background-color: var(--ion-color-primary, --base);

    h4 {
      color: white;
    }

    ion-icon {
      color: white;
    }

  }

}


.cassetta-sicurezza {
  background-color: white;
  border-radius: 0.5em;
  display: flex;
  border: 1px solid #DBDBDB;
  font-size: 16px;
  min-height: 4em;
  padding: 14px;
  height: 5.5rem;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  h4 {
    z-index: 1;
    color: var(--cassetta-empty, --base);
    font-size: 16px !important;
    font-weight: 600;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    cursor: pointer;
  }

  .max-lines-two {
    -webkit-line-clamp: 2 !important;
  }

  .centered-text {
    margin: auto !important;
    cursor: pointer;
  }

  .padding-left {
    padding-left: 2vh;
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 0;
  }

  .icon-right {
    zoom: 1.2;
    font-weight: bold;
    position: absolute;
    right: 0.9em;
    top: 1.15em;
  }

  ion-icon {
    color: var(--ion-color-primary, --base);
  }

  .logo-img {
    width: 65px;
    height: 45px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .logo-coin {
    width: 45px;
  }

  .guadagno {
    color: var(--punti-acquisiti, --base);
    font-weight: bold;
    font-size: 2vh;
    margin: auto !important;
    text-align: left !important;
    cursor: pointer;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.aggiunta {
    border: none;
    background-color: var(--ion-color-primary, --base);

    h4 {
      color: white;
    }

    ion-icon {
      color: white;
    }

    .option-button {
      top: 5px;
      // padding e margin sono necessari per rendere l'area clickabile piu' grande, sono opposti per fare in modo che rimanga nella sua posizione iniziale
      padding: 0.2em 0.5em 0.5em 0.5em;
    }
  }

  %labels {
    font-size: 14px;
  }

  .data-status {
    @extend %labels;
    font-size: 13px;
    margin: auto 0;
    opacity: 0.5;
    padding-left: 2px;
  }

  .active {
    opacity: 1 !important;

  }


  .investment-status {
    @extend %labels;
    font-size: 13px;
    margin: auto 0;
    opacity: 0.5;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 2px;
  }

  .cassette-bonus {
    color: var(--punti-acquisiti, --base);
    font-size: 15px;
    margin: auto 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 8px;
    font-weight: bold;
  }

  .status-img {
    @extend %center-column;

    img {
      width: 1.3em;
    }
  }

  %center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .status-info {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    img {
      width: 1.2em;
      max-height: 1.2em;
      opacity: 0.5;
    }

    span {
      cursor: pointer;
    }
  }

}

.logo-img {
  background-image: url('../assets/imgs/icon_cassette/icon_amazon_inactive@3x.png');
}

.aggiunta {
  background-color: var(--ion-color-primary, --base);

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_amazon_color@3x.png');
  }

  h4,
  span {
    color: white;
  }

  .svg {
    fill: white;
    width: 15px;
    height: 15px;
  }
}

.non-afflusso {
  border: 2px solid var(--ion-color-primary);

  h4,
  span {
    color: var(--ion-color-primary);
  }

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_amazon_color@3x.png');
  }

  .svg {
    fill: var(--ion-color-primary, --base);
    width: 15px;
    height: 15px;
  }
}


.interests-and-survey-cassetta {
  @extend .cassetta;

  .centered-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: auto !important;
  }

  font-size: 16px;
  min-height: 4em;
  padding: 14px !important;
  height: 8rem;
  border-color: var (--cassetta-full, --base);
  align-items: center;
  flex-direction: row;

  &.aggiunta {

    .option-button {
      top: 4px;
      // padding e margin sono necessari per rendere l'area clickabile piu' grande, sono opposti per fare in modo che rimanga nella sua posizione iniziale
      padding: 0.2em 0.5em 0.5em 0.5em;
    }
  }
}

.add-cassetta {
  @extend .cassetta;
  background-color: white;
  border: 1px solid;
  border-color: lightgrey;
  min-height: 6.8em;

  .center-image {
    max-width: 45%;
    margin: auto;
  }

  .disabled {
    opacity: 0.2;
  }

}

.cassetta-row {

  .img-card {
    width: 68px;
  }

}

// === CASSETTE - FINE ===

// === CASSETTA CON IMMAGINE ===

.cassetta-img {
  background-position: center;
  background-size: cover !important;
  height: 9.6em;
  width: 100%;
  border-radius: 0.5em;
  z-index: 0;
  justify-content: center;
  display: inline-grid;
  align-items: flex-end;

  p {
    margin: 0;
    width: 100%;
    margin-bottom: 0.8em;
    font-size: 1.1em;
    font-weight: 600;
    color: white;
    z-index: 2;
    text-align: center;

    @include small-phone() {
      font-size: 1em;
    }
  }

  img {
    z-index: 2;
  }
}

.cassetta-img-grey {
  @extend .cassetta-img;
  padding: 0 8px 8px 8px !important;
  background-color: #e2e2e2 !important;
  height: 7.6em !important;
  border: 2px solid #009dcc !important;

  p {
    font-size: 12px !important;
  }
}

.option-button-img-base {
  .option-button-img {
    color: #FFF;
  }
}

.IG {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_instagram_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--instagram, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_instagram_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--instagram, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_instagram_color@3x.png');
    }

    h4,
    span {
      color: var(--instagram, );
    }

    .svg {
      fill: var(--instagram, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--instagram, );
    }
  }
}

.FB {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_facebook_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--facebook);

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_facebook_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;

  }

  &.non-afflusso {
    border: 2px solid var(--facebook);

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_facebook_color@3x.png');
    }

    h4,
    span {
      color: var(--facebook, );
    }

    .svg {
      fill: var(--facebook, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--facebook, );
    }
  }
}




.TW {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_twitter_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--twitter, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_twitter_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--twitter, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_twitter_color@3x.png');
    }

    h4,
    span {
      color: var(--twitter, );
    }

    .svg {
      fill: var(--twitter, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--twitter, );
    }
  }
}

.LI {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_linkedin_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--linkedin, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_linkedin_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--linkedin, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_linkedin_color@3x.png');
    }

    h4,
    span {
      color: var(--linkedin, );
    }

    .svg {
      fill: var(--linkedin, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--linkedin, );
    }
  }
}

// ACCOUNT

.GO {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_google_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--google, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_google_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--google, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_google_color@3x.png');
    }

    h4,
    span {
      color: var(--google, );
    }

    .svg {
      fill: var(--google, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--google, );
    }
  }
}

.AP {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_apple_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--apple, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_apple_color@3x.png');
    }

  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--apple, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_apple_color@3x.png');
    }

    h4,
    span {
      color: var(--apple, );
    }

    .svg {
      fill: var(--apple, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--apple, );
    }
  }
}

// SOCIAL

//PLACEHOLDER ICON CASSETTE
.loyalty {
  .logo-img {
    background-image: url("../assets/imgs/icon_cassette/icon_supermercato_generico_color@3x.png");
  }
}

.ecommerce {
  .logo-img {
    background-image: url("../assets/imgs/icon_cassette/icon_ecommerce_color@3x.png");
  }
}

#livello_1 {
  enable-background: new 0 0 18 18;
}


.rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);

}


// LOYALTY

.CAR {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_carrefour_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--carrefour, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_carrefour_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--carrefour, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_carrefour_color@3x.png');
    }

    h4,
    span {
      color: var(--carrefour, );
    }

    .svg {
      fill: var(--carrefour, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--carrefour, );
    }
  }
}

.CON {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_conad_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--conad, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_conad_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--conad, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_conad_color@3x.png');
    }

    h4,
    span {
      color: var(--conad, );
    }

    .svg {
      fill: var(--conad, base) !important;
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--conad, );
    }
  }
}

.COO {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_coop_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--coop, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_coop_color@3x.png');
    }

  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--coop, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_coop_color@3x.png');
    }

    h4,
    span {
      color: var(--coop, );
    }

    .svg {
      fill: var(--coop, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--coop, );
    }
  }
}

.ESS {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_esselunga_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--esselunga, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_esselunga_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--esselunga, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_esselunga_color@3x.png')
    }

    h4,
    span {
      color: var(--esselunga, );
    }

    .svg {
      fill: var(--esselunga, base) !important;
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--esselunga, );
    }
  }
}


.GIG {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_il_gigante_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--gigante, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_il_gigante_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--gigante, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_il_gigante_color@3x.png');
    }

    h4,
    span {
      color: var(--gigante, );
    }

    .svg {
      fill: var(--gigante, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--gigante, );
    }
  }
}

.IPE {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_iper_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--iper, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_iper_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--iper, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_iper_color@3x.png');
    }

    h4,
    span {
      color: var(--iper, );
    }

    .svg {
      fill: var(--iper, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--iper, );
    }
  }
}

.UNE {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_unes_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--unes, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_unes_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--unes, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_unes_color@3x.png');
    }

    h4,
    span {
      color: var(--unes, );
    }

    .svg {
      fill: var(--unes, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--unes, );
    }
  }
}

.AUC {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_auchan_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--auchan, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_auchan_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--auchan, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_auchan_color@3x.png');
    }

    h4,
    span {
      color: var(--auchan, );
    }

    .svg {
      fill: var(--auchan, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--auchan, );
    }
  }
}

.IKE {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_ikea_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--ikeaFamily, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_ikea_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--ikeaFamily, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_ikea_color@3x.png');
    }

    h4,
    span {
      color: var(--ikeaFamily, );
    }

    .svg {
      fill: var(--ikeaFamily, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--ikeaFamily, );
    }
  }
}

.DEC {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_decathlon_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--decathlon, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_decathlon_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--decathlon, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_decathlon_color@3x.png');
    }

    h4,
    span {
      color: var(--decathlon, );
    }

    .svg {
      fill: var(--decathlon, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--decathlon, );
    }
  }
}

.MED {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_mediaworld_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--mediaworld, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_mediaworld_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--mediaworld, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_mediaworld_color@3x.png');
    }

    h4,
    span {
      color: var(--mediaworld, );
    }

    .svg {
      fill: var(--mediaworld, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--mediaworld, );
    }
  }
}

.LTC {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_la_tua_card_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--laTuaCard, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_la_tua_card_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--laTuaCard, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_la_tua_card_color@3x.png');
    }

    h4,
    span {
      color: var(--laTuaCard, );
    }

    .svg {
      fill: var(--laTuaCard, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--laTuaCard, );
    }
  }
}

.PAN {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_panorama_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--panorama, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_panorama_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--panorama, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_panorama_color@3x.png');
    }

    h4,
    span {
      color: var(--panorama, );
    }

    .svg {
      fill: var(--panorama, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--panorama, );
    }
  }
}

.COI {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_coin_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--coin, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_coin_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--coin, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_coin_color@3x.png');
    }

    h4,
    span {
      color: var(--coin, );
    }

    .svg {
      fill: var(--coin, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--coin, );
    }
  }
}

.UNI {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_unieuro_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--unieuro, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_unieuro_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--unieuro, );
    opacity: 0.6;

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_unieuro_color@3x.png');
    }

    h4,
    span {
      color: var(--unieuro, );
    }

    .svg {
      fill: var(--unieuro, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--unieuro, );
    }
  }
}

.AES {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_acqua_e_sapone_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--acquaESapone, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_acqua_e_sapone_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--acquaESapone, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_acqua_e_sapone_color@3x.png');
    }

    h4,
    span {
      color: var(--acquaESapone, );
    }

    .svg {
      fill: var(--acquaESapone, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--acquaESapone, );
    }
  }
}

.FEL {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_feltrinelli_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--feltrinelli, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_feltrinelli_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--feltrinelli, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_feltrinelli_color@3x.png');
    }

    h4,
    span {
      color: var(--feltrinelli, );
    }

    .svg {
      fill: var(--feltrinelli, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--feltrinelli, );
    }
  }
}

.PEW {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_pewex_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--pewex, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_pewex_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--pewex, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_pewex_color@3x.png');
    }

    h4,
    span {
      color: var(--pewex, );
    }

    .svg {
      fill: var(--pewex, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--pewex, );
    }
  }
}

.CIS {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_cisalfa_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--cisalfaSport, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_cisalfa_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--cisalfaSport, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_cisalfa_color@3x.png');
    }

    h4,
    span {
      color: var(--cisalfaSport, );
    }

    .svg {
      fill: var(--cisalfaSport, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--cisalfaSport, );
    }
  }
}

.MDX {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_md_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--md, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_md_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {
    border: 2px solid var(--md, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_md_color@3x.png');
    }

    h4,
    span {
      color: var(--md, );
    }

    .svg {
      fill: var(--md, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--md, );
    }
  }
}

.ALT_LOYALTY {
  &.aggiunta {
    background-color: var(--ion-color-primary);

    .logo-img {
      background-image: url("..//assets/imgs/icon_cassette/icon_supermercato_generico_color@3x.png");
    }
  }
}



// ECOMMERCE

.ZN {
  @extend .option-button-img-base;

  .logo-img {
    background-image: url('../assets/imgs/icon_cassette/icon_amazon_inactive@3x.png');
  }

  &.aggiunta {
    background-color: var(--amazon, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_amazon_color@3x.png');
    }
  }

  &.sospeso {
    opacity: 0.3;
  }

  &.non-afflusso {

    border: 2px solid var(--amazon, );

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_amazon_color@3x.png');
    }

    h4,
    span {
      color: var(--amazon, );
    }

    .svg {
      fill: var(--amazon, );
      width: 15px;
      height: 15px;

      &.rotate {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .option-button-img {
      color: var(--amazon, );
    }
  }
}



// == FINE CASSETTA CON IMMAGINE

// INIZIO CASSETTA PRODOTTI CON IL CUORE PER I LIKE

.cassetta-product {
  @extend .cassetta;
  height: 14em;
  margin: 12px;

  .product-title {
    font-size: 2em;
    color: white;
    font-weight: 600;
    margin: 5.1em 0.6em 0;
  }

  .checkbox {
    position: fixed;
    right: 1em;
    padding: 1em;
  }

  .checkbox-icon {
    width: 2.7em !important;
    height: 2.7em !important;
    background-size: contain;
    background-color: transparent !important;
    border-width: 0 !important;
    background-image: url(../assets/imgs/icon_check/icon_check_box_inactive@3x.jpg) !important;
  }

  .checkbox-checked {
    .checkbox-inner {
      width: 2.7em !important;
      height: 2.7em !important;
      background-size: contain;
      border-width: 0 !important;
      left: 0 !important;
      background-image: url(../assets/imgs/icon_check/icon_check_like_active@3x.jpg) !important;
      transform: rotate(0) !important;
    }
  }

}

// FINE CASSETTA PRODOTTI

//INIZIO LAYOUT SCROLLABILE ORIZZONTALMENTE CON LE CASSETTE INTERESSI
.interest-scroll-container {
  width: 100%;
  height: 140px;
  white-space: nowrap;
  overflow: hidden;

  .cassetta-scroll {
    @extend .cassetta-img;
    width: 130px;
    margin: 17px 6px;

    .transparent {
      background: transparent !important;
    }

    .item-text {
      color: white;
      font-size: 12px !important;
      margin: 0;
      padding-bottom: 14px;
      display: -webkit-box !important;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;
      overflow: hidden !important;
      font-weight: 600;
    }

  }

  .scroll-content {
    padding-left: 6px;
  }

  .inner {
    height: 100%;
    margin: 26px 0;
  }

  .item-inner {
    padding: 0 !important;
  }
}

//FINE LAYOUT SCROLLABILE ORIZZONTALMENTE CON LE CASSETTE INTERESSI

.aggiungi-button-profilo {
  @extend .cassetta;
  align-items: center;
  border: 1px solid var(--ion-color-primary, --base) !important;
  cursor: pointer;

  h4 {
    color: var(--ion-color-primary, --base);
    font-size: 16px;
  }
}

.aggiungi-button {
  @extend .cassetta-sicurezza;
  border: 1px solid var(--ion-color-primary, --base) !important;
}

.aggiungi-button-outline {
  @extend .cassetta-sicurezza;
  height: 5.6rem;
  border: 1px solid #DBDBDB;
}

button.outline {
  border: 1px solid var(--ion-color-primary, --base) !important;
  font-size: 16px;
  color: var(--ion-color-primary, --base);
}

.button-ios {
  height: 5rem;
}

.button-md {
  height: 5rem;
}

.button-ios-primary.activated {
  background-color: #0eb1e2 !important;
}

.section-description {
  font-size: 22px;
  margin: 0;
  padding: 14px 8px 14px 22px;
  background-color: white;
  text-align: center;
}

.list-md {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin: 0;
}

.more-content-container {
  margin-top: 24px;

  .questionari-title {
    font-weight: 600;
    font-size: 20px;
    padding: 4px;
    margin: 0;
    text-align: center;
  }

  .questionari-description {
    margin: 26px 6px;
  }

  .go-to-survey {
    width: 97%;
    height: 7rem;
    background-position: center;
    background-size: cover;
    justify-content: center;
    position: relative;
    display: inline-grid;
    border-radius: 6px;

    span {
      color: white;
      font-size: 1.8rem;
      font-weight: bold;
      z-index: 1 !important;
      margin: auto;
    }

    &:after {
      content: " ";
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0.8;
      border-radius: 6px;
    }
  }

}

.btn-add {
  color: #fff;
  padding: 1.2em;
  border-radius: 6px;

  span {
    font-size: 16px;
  }
}

//BOTTONE OPZIONE CON TRE PALLINI PRESENTE SU SU SOCIAL E LOYALTY CARDS
.option-button {
  top: 4px;
  // padding e margin sono necessari per rendere l'area clickabile piu' grande, sono opposti per fare in modo che rimanga nella sua posizione iniziale
  padding: 0.2em 0.5em 0.5em 0.5em;
  right: 10px;
  z-index: 1;
  position: absolute;

  .select-icon-inner {
    color: transparent !important;
  }

  .select-text {
    color: transparent !important;
  }
}

.option-button-img {
  @extend .option-button;
  margin: 1px;
  font-size: 1.4em;
  right: 1px;
  color: var(--ion-color-primary);
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(1.15);
  }
}

.option-button-img-add {
  font-size: 1.4em;
}

//GRIGLIA UTILIZZATA NEI DETTAGLI DELLE CASSETTE
.cassetta-detail-grid {
  margin-bottom: 1rem;

  .item {
    background: transparent;
  }

  .additionaldata-item {
    ion-label {
      margin: 0 !important;
    }
  }

  .grid-title {
    font-size: 16px;
    color: black !important;
    font-weight: 300;
    margin-right: 15px;
  }

  .grid-description {
    margin-bottom: 13px !important;
    font-size: 20px;
    color: black !important;
  }

}

.checkbox-item {
  margin-top: 1.2em !important;
  min-height: 0 !important;

  .checkbox {
    margin: 0 !important;
  }

  .label {
    font-size: 13px;
    margin: 0 12px !important;
    padding: 0 !important;
    white-space: normal !important;
    font-weight: 500 !important;
    letter-spacing: 0em;
    line-height: 18px;
  }

  .item-cover {
    z-index: -1 !important;
  }

}

// ICONE TAB - start

drawer-menu {
  height: 100%;

  .menu-slot>ion-list.profile {
    margin-bottom: 28px;
  }
}

ion-icon[name*="weople-investi"] {
  content: url(../assets/imgs/icon/investi_inactive@3x.png);
}

.tab-selected ion-icon[name*="weople-investi"],
.drawer-menu ion-icon[name*="weople-investi"] {
  content: url(../assets/imgs/icon/caveu_color@3x.png);
}

ion-icon[name*="weople-premi"] {
  content: url(../assets/imgs/icon/icon_gain_relax_inactive.png);
}

.tab-selected ion-icon[name*="weople-premi"],
.drawer-menu ion-icon[name*="weople-premi"] {
  content: url(../assets/imgs/icon/icon_gain_relax_active.png);
}

ion-icon[name*="weople-esercita"] {
  content: url(../assets/imgs/icon/attiva_diritti_inactive@3x.png);
}

.tab-selected ion-icon[name*="weople-esercita"],
.drawer-menu ion-icon[name*="weople-esercita"] {
  content: url(../assets/imgs/icon/attiva_diritti_color@3x.png);
}

ion-icon[name*="weople-trasparenza"] {
  content: url(../assets/imgs/icon/trasparenza_color@3x.png);
}

ion-icon[name*="weople-notifiche"] {
  content: url(../assets/imgs/icon/notifiche_color@3x.png);
}

ion-icon[name*="weople-news"] {
  content: url(../assets/imgs/icon/news_color@3x.png);
}

ion-icon[name*="weople-chi-siamo"] {
  content: url(../assets/imgs/icon/chi_siamo_color@3x.png);
}

ion-icon[name*="weople-help"] {
  content: url(../assets/imgs/icon/aiuto_color@3x.png);
}

//=== END

// per dev === START
ion-icon[ng-reflect-is-active="true"] {
  @include tabIconActive();
}

ion-icon[ng-reflect-is-active="false"] {
  @include tabIconInactive();
}

.tab-button-icon {
  font-size: 3.3rem !important;
  overflow: initial !important;
}

.tab-button-text {
  margin-top: 0 !important;
}

.btn-close-toolbar {
  zoom: 1.2;
  font-weight: bold;
  color: black;
}

.top-container-cassette {
  background-color: white;
  display: grid;
}

// ==== BARRA PUNTI - inizio ====
.pointStep-box-container {
  width: 83%;
  height: 6px;
  margin: 7.8rem auto 4.6rem;
  background: #F6F6F6;
  border: 1px solid #F6F6F6;
  border-radius: 4px !important;
}

.pointStep-box-1,
.pointStep-box-2,
.pointStep-box-3,
.pointStep-box-4,
.pointStep-box-5,
.pointStep-box-real {
  float: left;
  height: 5px;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  background-color: var(--ion-color-primary, --base);
}

.pointStep-box-1 {
  width: 25%;
}

.pointStep-box-2 {
  width: 50%;
}

.pointStep-box-3 {
  width: 75%;
}

.pointStep-box-4 {
  width: 100%;
}

.pointStep-box-grabbed {
  float: left;
  height: 5px;
  width: 0%;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  background-color: #dbdbdb;
}

.pointStep-img-container {
  position: absolute;
  top: 0.2em;

  .img-small {
    width: 45px;
    max-width: 45px;
    padding-top: 6px;
  }

  .img-big {
    width: 55px;
    max-width: 55px;
  }

  .text-enabled {
    color: black;
    font-size: 12px;
    font-weight: 300;
  }

  .text-disabled {
    color: #848484;
    font-size: 12px;
    font-weight: 300;
  }

  .text-bold {
    color: black;
    font-size: 12px;
    font-weight: 700;
  }

  .col5 {
    width: 20%;
  }

  .level-title {
    color: black;
    font-size: 18px;
    margin: 0px auto 12px;
  }
}

// ==== BARRA PUNTI - fine ====

.divider {
  height: 1px !important;
  color: gray;
  width: 90%;
  margin: auto;
}

//Se si vuole gestire il footer con un div fixed bottom bisogna mettere
//un padding in fondo alla pagina (solo se sovrappone gli elementi)

.padding-footer {
  padding-bottom: 60px;
}

.footer-fixed-bottom {
  text-align: center;
  margin: auto;
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
  padding: 22px;
  background: white;
  border-top: 1px solid #ccc;
  z-index: 1;

  b {
    color: var(--ion-color-primary);
    cursor: pointer;
  }
}

.reversed {
  transform: rotate(180deg);
}

.suggestion-list {
  position: absolute;
  top: 6.4em;
  right: 0;
  z-index: 1;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: white;

  border: 1px solid var(--ion-color-primary, --base);
  border-radius: 4px;
  margin-bottom: 1rem;

  ion-list {
    height: 200px;
    overflow: scroll;
    margin-bottom: 1px;
  }

  ion-label {
    font-size: 13px;
    font-weight: normal;
  }
}

.suggestion-list-cassette {
  overflow: auto;
  max-height: 200px;
  max-width: 810px;
  margin: auto !important;
  z-index: 1;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  background-color: white;
  border: 1px solid var(--ion-color-primary, --base);
  border-radius: 4px;


  ion-list {
    overflow: hidden;
    margin-bottom: 1px;
  }

  ion-label {
    font-size: 13px;
    font-weight: normal;
  }
}

.cassetta-detail-img {

  .logo-img {
    height: 123px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center;
  }
}

.tabbar {
  background: white !important;
}

.btnActionSheetDisabled {
  span {
    color: #d2d2d2 !important;
  }
}

//UTILIZZATO SOLO NEI POPUP DI DETTAGLIO CASSETTE DI SICUREZZA
.colorFromTypeCode {

  // SOCIAL

  &.FB {
    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_facebook_color@3x.png');
    }

    .text-color {
      color: var(--facebook, --base);
    }
  }

  &.IG {
    .text-color {
      color: var(--instagram, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_instagram_color@3x.png');
    }
  }

  &.TW {
    .text-color {
      color: var(--twitter, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_twitter_color@3x.png');
    }
  }

  &.LI {
    .text-color {
      color: var(--linkedin, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_linkedin_color@3x.png');
    }
  }

  // ACCOUNT

  &.GO {
    .text-color {
      color: var(--google, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_google_color@3x.png');
    }
  }

  &.AP {
    .text-color {
      color: var (--apple, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_apple_color@3x.png');
    }
  }

  // LOYALTY

  &.CAR {
    .text-color {
      color: var(--carrefour, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_carrefour_color@3x.png');
    }
  }

  &.CON {
    .text-color {
      color: var(--conad, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_conad_color@3x.png');
    }
  }

  &.COO {
    .text-color {
      color: var(--coop, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_coop_color@3x.png');
    }
  }

  &.ESS {
    .text-color {
      color: var(--esselunga, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_esselunga_color@3x.png');
    }
  }

  &.GIG {
    .text-color {
      color: var(--gigante, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_il_gigante_color@3x.png');
    }
  }

  &.IPE {
    .text-color {
      color: var(--iper, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_iper_color@3x.png');
    }
  }

  &.UNE {
    .text-color {
      color: var(--unes, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_unes_color@3x.png');
    }
  }

  &.AUC {
    .text-color {
      color: var(--auchan, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_auchan_color@3x.png');
    }
  }

  &.IKE {
    .text-color {
      color: var(--ikeaFamily, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_ikea_color@3x.png');
    }
  }

  &.DEC {
    .text-color {
      color: var(--decathlon, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_decathlon_color@3x.png');
    }
  }

  &.MED {
    .text-color {
      color: var(--mediaworld, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_mediaworld_color@3x.png');
    }
  }

  &.LTC {
    .text-color {
      color: var(--laTuaCard, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_la_tua_card_color@3x.png');
    }
  }

  &.PAN {
    .text-color {
      color: var(--panorama, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_panorama_color@3x.png');
    }
  }

  &.COI {
    .text-color {
      color: var(--coin, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_coin_color@3x.png');
    }
  }

  &.UNI {
    .text-color {
      color: var(--unieuro, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_unieuro_color@3x.png');
    }
  }

  &.AES {
    .text-color {
      color: var(--acquaESapone, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_acqua_e_sapone_color@3x.png');
    }
  }

  &.FEL {
    .text-color {
      color: var(--feltrinelli, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_feltrinelli_color@3x.png');
    }
  }

  &.PEW {
    .text-color {
      color: var(--pewex, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_pewex_color@3x.png');
    }
  }

  &.CIS {
    .text-color {
      color: var(--cisalfaSport, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_cisalfa_color@3x.png');
    }
  }

  &.MDX {
    .text-color {
      color: var(--md, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_md_color@3x.png');
    }
  }

  // ECOMMERCE

  &.ZN {
    .text-color {
      color: var(--amazon, --base);
    }

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_amazon_color@3x.png');
    }
  }

  &.ALT {
    .text-color {
      color: var(--altro, --base);
    }
  }

}

.survey {

  .logo-img {
    background-image: url("../assets/imgs/icon_cassette/icon_auto_descrizione_inactive@3x.png");
  }

  &.aggiunta {
    background-color: var(--ion-color-primary, --base);

    .logo-img {
      background-image: url("../assets/imgs/icon_cassette/icon_auto_descrizione_color@3x.png")
    }
  }
}

.interest {

  .logo-img {
    background-image: url("../assets/imgs/icon_cassette/icon_interessi_inactive@3x.png");
  }

  &.aggiunta {
    background-color: var(--ion-color-primary, --base);

    .logo-img {
      background-image: url('../assets/imgs/icon_cassette/icon_interessi_color@3x.png');
    }
  }
}

#cave-score {
  .men {
    display: none;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.stato-cassetta {
  .logo {
    padding-left: 0;
  }

  img {
    width: 1.5em;
  }

  .dati-label {
    display: flex;

    span {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 18px;
      font-weight: 300;
    }
  }

}

.detail-section-title {
  margin: 0 !important;
  font-size: 24px;
  font-weight: bold;
}

.guida {
  b {
    color: var(--ion-color-primary, --base);
  }
}

page-reset-password,
page-registration,
page-login,
page-modal-form-add-account,
page-add-loyalty {
  .label[stacked] {
    font-size: 10px;
    font-weight: bold;
    color: black;
    padding-left: 10px;
  }
}

.checked {
  left: 90%;
  bottom: 10%;
  position: absolute;
  background-repeat: no-repeat;
  width: 17px !important;
  height: 21px !important;
  background-size: contain;
  background-color: transparent !important;
  border-width: 0 !important;
  background-image: url('../assets/imgs/icon_status/icon_check_green@3x.png') !important;
}

u,
ins {
  text-underline-position: under;
}

.rounded-badge-drawer {
  border-radius: 84px;
  border: 1px solid #ed3833;
  background-color: #ed3833;
  color: white;
  height: 18px !important;
  width: 18px !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  top: 0px !important;
  right: -4px !important;
  text-align: left;

  .notification-number {
    font-size: 12px;
    font-weight: 400;
    line-height: 0;
    position: absolute;
    top: 8px;
    width: 20px;
    right: 0px;
    left: -2px;
    text-align: center;
  }

}

button.outline_custom {
  font-size: 14px !important;
  font-weight: bold;
  width: 36vh;
}

// Div guida area a fondo pagina
div.guida-area {
  text-align: center;
  margin-top: 12%;
  margin-bottom: 5%;

  span {
    color: var(--ion-color-primary, --base);
    border: 1px solid var(--ion-color-primary, --base);
    padding: 0 15px;
    border-radius: 13px;
  }

  a {
    text-decoration: none;

  }
}

div.guarda-video {
  margin-bottom: 50px;
  text-align: center;

  span {
    color: white;
    background-color: #009DCC;
    border: 1px solid var(--ion-color-primary, --base);
    padding: 0 15px;
    border-radius: 13px;
  }

  a {
    text-decoration: none;

  }
}

//Modifiche per tablet
@include tablet() {

  //Margine custom su header per bug su tablet ios
  ion-modal {
    .header-ios {
      margin-top: 16px !important;
    }
  }
}

.tablet-web-container {
  margin: auto !important;
  max-width: 850px;
}

.invia-codice-web {
  font-size: 14px !important;
  font-weight: bold;
  margin: auto;
  color: #009DCC;
  background-color: transparent;
  align-items: center;
  padding-bottom: 10px;

  .col {
    align-self: center;
  }

  .input-codice {
    text-align: center;
    border-width: 0;
  }

  .button-copy {
    font-size: 14px;
    height: 26px;
  }

}

.text-switch {
  font-size: 12px;
  margin: 0 1px 4px;
}

.custom-grid {
  background: var(--light-grey, --base);
  border-bottom: 1px solid var(--divider-color, base);
  padding: 0;
}

.custom-row {
  align-items: center;
  border-top: 1px solid var(--divider-color, base);
  border-left: 1px solid var(--divider-color, base);
  border-right: 1px solid var(--divider-color, base);
}

.custom-col {
  display: flex;
  flex-direction: row;
}

.custom-col-icon {
  display: flex;
  flex-direction: row;
  width: 100px;
}

.img-left {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  display: block;
  margin-left: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}


.toogle-custom {
  margin: auto;
}

.cassetta-title {
  font-size: 14px;
  margin-left: 12px;
  font-weight: bold;
  align-self: center;
}

.cid-divider {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 22rem;
}

.alert-message {
  overflow: hidden !important;
}

.guida-tutorial {
  color: #009DCC;
  border: 2px solid #009DCC;
  height: 5vh;
  font-weight: bold;
  background: white;
  border-radius: 22px;
  font-size: 2vh;
  max-width: 400px;
  width: 15vh;
}

.mobile {
  display: none;
}

.desktop {
  display: inline-block;
}

@media (max-width: 768px) {
  .mobile {
    display: inline-block;
  }

  .desktop {
    display: none;
  }
}

.alert-button-group {
  span {
    cursor: pointer;
  }
}

/* Part of css not included in the original document and manually added to this file (or highly modified) */

ion-col {
  text-align: center;
  margin: auto;
}

ion-chekbox {
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  margin-block-start: 1.5vh;
}

ion-button.btn-cancel {
  border-radius: 5px;
}

.popup-category-added {
  @extend ion-modal;

  &::part(content) {
    height: 40%
  }
}

.popup-perdita-bonus {
  @extend ion-modal;

  &::part(content) {
    height: 55%;
  }
}

.popup-delete-user {
  @extend ion-modal;

  &::part(content) {
    height: 65%;
  }
}

.popup-remove-avatar {
  @extend ion-modal;

  &::part(content) {
    max-height: 35%;


    @media screen and (max-width: 768px) {
      max-height: 35%;
      max-width: 80%;
    }
  }
}


.popup-ultimo-permesso {
  @extend ion-modal;

  &::part(content) {
    max-height: 40%;


    @media screen and (max-width: 768px) {
      max-height: 55%;
      max-width: 80%;
    }
  }
}

.popup-documents {
  @extend ion-modal;

  &::part(content) {
    height: 65%;
  }
}

.popup-confirm-upload {
  @extend ion-modal;

  &::part(content) {
    max-height: 35%;


    @media screen and (max-width: 768px) {
      max-height: 35%;
      max-width: 80%;
    }
  }
}

.popup-document-added {
  @extend ion-modal;

  &::part(content) {
    max-height: 35%;


    @media screen and (max-width: 768px) {
      max-height: 35%;
      max-width: 80%;
    }
  }

}

/* Keep it as it is also because it affect other popups like .popup-crea-archivio */
.popup-carica-dati {
  @extend ion-modal;

  .top-image-cassetta {
    margin-top: 3.25vh;
    height: 8vh;
    width: 8vh;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    display: inline-flex;
    align-self: center;
  }

  .text-connessione {
    font-size: 1.8vh;
    line-height: 3vh;
    color: #4d4d4d;
    margin: 2vh 0;
    padding-left: 1.5vh;
    padding-right: 1.5vh;
  }
}

.popup-livelli {
  @extend ion-modal;

  .text-connessione {
    font-size: 1.8vh;
    line-height: 3vh;
    color: #4d4d4d;
    margin: 2vh 0;
    padding-left: 1.5vh;
    padding-right: 1.5vh;
  }
}

.popup-delete-cassetta {
  @extend ion-modal;

  .top-image {
    margin: 9vh auto 3vh;
    width: 7vh;
  }

  .text {
    font-size: 2.4vh;
    line-height: 3vh;
    margin: 2vh 0;
  }
}

.popup-got {
  /* For this particular popup, height for desktop has been hardcoded */
  @extend ion-modal;

  &::part(content) {
    height: 75%;
  }

}

.popup-sintesi-contratto {
  @extend ion-modal;

  ion-content::part(scroll) {
    padding: 16px;
  }

  .text {
    font-size: 2.4vh;
    line-height: 2.8vh;
    margin: 2vh 0;
  }

  .popup-subtitle {
    font-size: 2.7vh;
  }

  .ion-scroll {
    height: 74vh;
  }

  //Modifiche per tablet
  @include tablet() {
    .ion-scroll {
      height: 440px !important;
    }
  }

}